import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import Input from "../../../components/elements/Input";
import CustomModal from "../../../components/modal/CustomModal";
import AuditListWithSearch from "./AuditListWithSearch";
import { useFormik } from "formik";
import moment from "moment";
import AccessControl from "../../../config/AccessControl";
import {
  AccessControlType,
  UserPermissionsConstant,
} from "../../../constants/constants";
import {
  deleteLocalTemplateHeaderByIds,
  GetAssigneeTemplatesByClientId,
  GetLocalTemplatesWithSchedule,
  StartAuditing,
} from "../../../actions/auditActions";
import { connect, useDispatch } from "react-redux";
import { clientType, ILocalTemplateType } from "../../../redux/modules/clientManagementReducer";
import { IGlobalTemplateType } from "../../../redux/modules/auditManagementReducer";
import { apiResponseType } from "../../../config/commonTypes";
import {
  DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_SUCCESS,
  GET_CRITERIA_LIST_BY_ID_SUCCESS,
  GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_FAILURE,
  GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_REQUEST,
  GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_SUCCESS,
  GET_TEMPLATE_BY_ID_SUCCESS,
} from "../../../constants";
import DataTable, { TableStyles } from "react-data-table-component";
import { BootyPagination } from "../../../components/pagination/BootyPagination";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import logoImage from "../../../assets/img/logo/logo.png";
import autoTable from "jspdf-autotable";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { ConfirmModal } from "../../../components/modal/ConfirmModal";
import { sortByDate, sortByString,compareDates,toLocalformatDate } from "../../../config/commonMethods";
import jwt from "jwt-decode";

interface IPropsTypes {
  templateList?: ILocalTemplateType[];
  isLoading?: boolean;
  clientId?: number;
  isClientAdmin?: boolean;
  selectedClientDetails? : clientType
}
const initialValues: any = {
  fromDate: "",
  toDate: "",
  auditBy: "",
  code: "",
};

const Home = ({ templateList, 
                isLoading, 
                clientId,
                isClientAdmin,
                selectedClientDetails }: IPropsTypes) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataTableFormat, setDataTableFormat] = useState<any>();
  const [filterValues, setFilterValues] = useState<any>(initialValues);
  const [assigneeList, setAssigneeList] = useState<ILocalTemplateType[] | []>(
    []
  );
  const [isDelete , setIsDelete] = useState<boolean>();
  const [auditIdForDelete , setAuditIdForDelete] = useState<number|null>();
  const url = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (url.pathname === "/add-new-audit") setIsOpenModal(true);
    else setIsOpenModal(false);
  }, [url]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilterValues(values);
      const isLoadFiltered =
        Object.values(values).filter((f) => f !== "").length > 0;
      if (!isLoadFiltered && templateList) {
        const dataSet = formatDataTable(templateList);
        setDataTableFormat(dataSet);
      } else templateList && filterDatas(templateList, values);
    },
  });

  const filterDatas = (templates: ILocalTemplateType[], filterValuesP: any) => {
    const isLoadFiltered =
      Object.values(filterValuesP).filter((f) => f !== "").length > 0;
    if (isLoadFiltered) {
      const cloneALlOriginalTemplateList: ILocalTemplateType[] =
        templates && templates?.length > 0 ? [...templates] : [];
      const filterTemplates = cloneALlOriginalTemplateList?.filter(
        (template: any) =>
          (filterValuesP?.fromDate
            ? moment(template.startDate, "YYYY-MM-DD").isSameOrAfter(
                filterValuesP?.fromDate
              )
            : true) &&
          (filterValuesP?.toDate
            ? moment(template.startDate, "YYYY-MM-DD").isSameOrBefore(
                filterValuesP?.toDate
              )
            : true) &&
          (filterValuesP?.code
            ? template?.localTemplateCode
                .toLowerCase()
                .includes(filterValuesP?.code?.toLowerCase())
            : true) &&
          (filterValuesP?.auditBy && template.startBy
            ? template?.startBy
                .toLowerCase()
                .includes(filterValuesP?.auditBy?.toLowerCase())
            : true)
      );
      const dataSet = formatDataTable(filterTemplates);
      setDataTableFormat(dataSet);
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchAllAssigneeTemplates(clientId);
    }
  }, [clientId]);

  const fetchAllAssigneeTemplates = (client_Id: number) => {
    dispatch({
      type: GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_REQUEST,
    });
    GetLocalTemplatesWithSchedule(
      client_Id,
      (data: IGlobalTemplateType[]) => {
        dispatch({
          type: GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_SUCCESS,
          payload: data,
        });
      },
      (data: apiResponseType) => {
        dispatch({
          type: GET_INTERNAL_AUDIT_LOG_AUDIT_MANAGEMENT_FAILURE,
        });
        console.log("##CAN NOT FETCH");
      }
    );
  };

  useEffect(() => {
    if (templateList) {
      const isLoadFiltered =
        Object.values(filterValues).filter((f) => f !== "").length > 0;
      if (isLoadFiltered) {
        filterDatas(templateList, filterValues);
      } else {
        const dataSet = formatDataTable(templateList);
        setDataTableFormat(dataSet);
      }
    }
  }, [templateList]);

  const customStyles: TableStyles = {
    headRow: {
      style: { backgroundColor: "#f2f2f2", border: "1px solid #d9dee3" },
    },
    rows: {
      style: { border: "1px solid #d9dee3", cursor: "pointer" },
    },
  };

  //This is added to date sorting bug fix
  const compareStartDate = (rowA: any, rowB: any) => {
    // Parse the date strings using Moment.js
    const dateA = rowA.date  ? moment(rowA.date, "DD/MM/YYYY") : null;
    const dateB = rowB.date  ? moment(rowB.date, "DD/MM/YYYY") : null;
    return compareDates(dateA, dateB);
  };
  const comparCloseeDate = (rowA: any, rowB: any) => {
    // Parse the date strings using Moment.js
    
    const dateA = rowA.status? moment(rowA.status, "DD/MM/YYYY") : null;
    const dateB = rowB.status? moment(rowB.status, "DD/MM/YYYY") : null;
    return compareDates(dateA, dateB);
  };
  

  const dynamicColums :any = [
    {
      name: "Date",
      id: "date",
      selector: (row: any) => row.date,
      sortable: true,
      width: "120px",
      sortFunction: compareStartDate,
    },
    {
      name: "By",
      id: "by",
      selector: (row: any) => row.by,
      sortable: true,
      minWidth: "140px",
    },
    {
      name: "Code",
      id: "code",
      selector: (row: any) => row.code,
      sortable: true,
      width: "120px",
    },
    {
      name: "Audit Name",
      id: "auditName",
      selector: (row: any) => row.auditName,
      sortable: true,
      wrap: true,
      minWidth: "140px",
    },
    {
      name: "Progress",
      selector: (row: any) => row.progressPercentage,
      sortable: true,
      width: "120px",
    },
    {
      name: "Closed",
      selector: (row: any) => row.status,
      sortable: true,
      width: "120px",
      sortFunction: comparCloseeDate,
    }
  ];
  const [columns, setColumns] = useState<any>([...dynamicColums]);

  // TODO fix delete action issu
  useEffect(()=>{
    if (!isClientAdmin) {
      setColumns([...dynamicColums, {
        name: "Action",
        selector: (row: any) => row.action,
        sortable: false,
        width: "100px",
      }])
    } else {
      setColumns(dynamicColums);
    }
  },[isClientAdmin]);

  const deleteActions = (id:number)=>{
    return (
      <span>
        <AccessControl type={AccessControlType.DISABLE}
          allowedPermissions={[UserPermissionsConstant.MimicAccess]}>
          <button type="button"
            onClick={() => {
              setIsDelete(true);
              setAuditIdForDelete(id);
            }}
            className="btn btn-outline-danger btn-xs app-btn-xs py-0">
            <i className="bx bx-trash-alt  small"></i>
          </button>
        </AccessControl>
      </span>
    )
  }

  const formatDataTable = (templates: ILocalTemplateType[]) => {
    const data: any = templates?.map((template: ILocalTemplateType) => {
      return {
        localTemplateHeaderId: template.localTemplateHeaderId,
        //date: moment(template.startDate).format("DD/MM/YYYY"),
        date: toLocalformatDate(template.startDate),
        startDateForOrder: template.startDate,
        by: template.startBy,
        code: template.localTemplateCode,
        auditName: template.localTemplateName,
        progressPercentage: template.progress,
        //status: template.isClosed ? (template.completedDate == null)?"-": moment(template.completedDate).format("DD/MM/YYYY"): "-",
        status: template.isClosed ? (template.completedDate == null)?"-": toLocalformatDate(template.completedDate): "-",
        action: template?.localTemplateHeaderId && deleteActions(template?.localTemplateHeaderId)
      };
    });
    return _.orderBy(data, ["startDateForOrder"], ["desc"]);
  };

  useEffect(() => {
    isOpenModal &&
      clientId &&
      GetAssigneeTemplatesByClientId(
        clientId,
        (data: ILocalTemplateType[]) => {
          setAssigneeList(data);
        },
        (error: apiResponseType) => {
         // navigate("/internal-audit-log");
          toast.error(
            error?.message ? error.message : null
          );
        }
      );
  }, [isOpenModal]);

  const onStartAudit = (template: ILocalTemplateType) => {
    navigate("/internal-audit-log");
    clientId &&
      StartAuditing(
        {
          globalTemplateHeaderId: template.globalTemplateHeaderId,
          clientId: clientId,
        },
        (response: apiResponseType) => {
          fetchAllAssigneeTemplates(clientId);
          toast.success(
            response?.message ? response.message : "Successfully started audit"
          );
          dispatch({
            type: GET_CRITERIA_LIST_BY_ID_SUCCESS,
            payload: [],
          });
          dispatch({
            type: GET_TEMPLATE_BY_ID_SUCCESS,
            payload: undefined,
          });
          navigate(
            "/internal-audit-results/" + response.data.createdLocalTemplateId
          );
        },
        (error: apiResponseType) => {
          toast.error(error?.data ? error.data : "Something went wrong");
        }
      );
  };

  const [sortedData , setSortesData] = useState<any>();

  const generatePDF = () => {
    if (dataTableFormat?.length < 1 || dataTableFormat === undefined) {
      toast.info("There is no data to print");
      return;
      }
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; //// portrait or landscape
    var doc = new jsPDF(orientation, unit, size);

    doc.addImage(logoImage, "PNG", 10, 10, 70, 70);
    doc.setFont("times");
    doc.setTextColor(65, 144, 197);
    doc.text("GIA+ Quality Assurance system", 100, 30);
    doc.text("__________________________", 100, 40);
    doc.setTextColor(0, 0, 0);
    doc.text("Internal Audit List", 100, 60);

    autoTable(doc, {
      head: [],
      margin: {
        top: 80,
      },
      theme:'plain',
      body: [
        ['Client', selectedClientDetails?.companyName ? selectedClientDetails?.companyName :'']
      ],
      columnStyles: {
        0:{ cellWidth: 80, textColor:'#3F3F3F'},
        1:{ overflow: "linebreak",minCellWidth:100, textColor:'#6D6D6D'},
      }
    });

    const headers: string[] = [
      "Date",
      "By",
      "Code",
      "Audit Name",
      "Progress",
      "Closed",
    ];
    const headKey: string[] = [
      "date",
      "by",
      "code",
      "auditName",
      "progressPercentage",
      "status",
    ];
    let data: any = [];
    if(sortedData?.length > 0){
      sortedData?.forEach((row: any) => {
        let rowData: any[] = [];
        headKey?.forEach((key: string) => {
          rowData.push(row[key]);
        });
        data.push(rowData);
        rowData = [];
      });
    }else{
      const initSortedData = sortByString(dataTableFormat, true,'code');
      initSortedData?.forEach((row: any) => {
        let rowData: any[] = [];
        headKey?.forEach((key: string) => {
          rowData.push(row[key]);
        });
        data.push(rowData);
        rowData = [];
      });
    }

    autoTable(doc, {
      head: [headers],
      margin: {
        top: 100,
      },
      body: data,
    });
    doc.save(`Internal-Audit-List-${moment().format('DD-MM-YYYY-hh-mm-ss')}.pdf`);
  };

  const deleteConfirmAudit =(id:number)=>{
    setIsDelete(false);
    setAuditIdForDelete(null);
    deleteLocalTemplateHeaderByIds([id],
      (response:apiResponseType) => {
        toast.success(response?.message ? response.message : 'Successfully deleted!');
        dispatch({
          type: DELETE_INTERNAL_AUDIT_REMOVE_TEMPLATE_SUCCESS,
          payload: id
        });
    },
    (error:apiResponseType) => {
        toast.error(error?.message ? error.message : 'Delete action failed!');
    });
  }
  return (
    <AppLayout>
      <>
        {isDelete && (
          <ConfirmModal
            onCanceled={() => setIsDelete(false)}
            isOpenConfirmModal={isDelete}
            title={"Delete Internal Audit"}
            btn1Text={"Cancel"}
            btn1Class={"btn btn-outline-secondary btn-sm"}
            btn2Text={"Confirm"}
            btn2Class="btn btn-danger btn-sm"
            icon={<i className='bx bx-error-circle display-4 app-col link-warning'></i>}
            message={"Are you sure you want to delete this item?"}
            onConfirm={() => auditIdForDelete && deleteConfirmAudit(auditIdForDelete)}
          />
        )}
        {isOpenModal && (
          <CustomModal
            size="lg"
            isClose={!isOpenModal}
            title="Select Audit To Complete"
            onClose={() => navigate("/internal-audit-log")}
          >
            <AuditListWithSearch
              datas={assigneeList?.filter(x=> !x.isRemoved)}
              onSelect={(template: ILocalTemplateType) =>
                onStartAudit(template)
              }
            />
          </CustomModal>
        )}
        <div className="row">
          {/*Custom style1 Breadcrumb */}
          <div className="col-md">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1">
                <li className="breadcrumb-item d-flex align-items-center">
                  <a href="javascript:void(0);">Home</a>
                              </li>
                              <li className="breadcrumb-item d-flex align-items-center">
                                  <a>Internal Audits</a>
                              </li>
                <li className="breadcrumb-item d-flex align-items-center active">
                                  Internal Audit logs
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="accordion" id="accordionExample">
              <div className="card accordion-item shadow-sm active">
                <h6 className="accordion-header" id="headingOne">
                  <button
                    type="button"
                    className="accordion-button border-bottom"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionOne"
                    aria-expanded="true"
                    aria-controls="accordionOne"
                  >
                    Search Filters
                  </button>
                </h6>

                <div
                  id="accordionOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <form className="w-100" onSubmit={formik.handleSubmit}>
                    <div className="accordion-body pt-2">
                      <div className="row">
                        <div className="col-3">
                          <label
                            className={
                              "col-form-label  text-capitalize fw-semibold"
                            }
                          >
                            From Date
                          </label>
                          <div className="col-md">
                            <input
                              id="fromDate"
                              name="fromDate"
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.handleSubmit();
                              }}
                              type="date"
                              className="form-control"
                              max={
                                filterValues?.toDate
                                  ? new Date(filterValues.toDate)
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <label
                            className={
                              "col-form-label  text-capitalize fw-semibold"
                            }
                          >
                            To Date
                          </label>
                          <div className="col-md">
                            <input
                              name="toDate"
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.handleSubmit();
                              }}
                              type="date"
                              className="form-control"
                              min={
                                filterValues?.fromDate
                                  ? new Date(filterValues.fromDate)
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <Input
                            name="auditBy"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.handleSubmit();
                            }}
                            label="Audit By"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label  text-capitalize fw-semibold"
                          />
                        </div>
                        <div className="col-3">
                          <Input
                            name="code"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.handleSubmit();
                            }}
                            label="Code"
                            inputClasses="form-control form-control-sm"
                            labelClasses="col-form-label  text-capitalize fw-semibold"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="py-3 pb-1 d-flex flex-wrap justify-content-end d-grid gap-2">
                          <AccessControl
                            type={AccessControlType.DISABLE}
                            allowedPermissions={[
                              UserPermissionsConstant.ViewPrintAudit,
                              UserPermissionsConstant.MimicAccess,
                            ]}
                          >
                            <button
                              type="button"
                              onClick={() => generatePDF()}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              <span className="tf-icons bx bx-printer"></span>
                              &nbsp; Print
                            </button>
                          </AccessControl>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Bordered Table */}
        <div className="row">
          <div className="col-md">
            <div className="card shadow-sm mt-4">
              <div className="d-flex justify-content-between align-items-center card-header mb-1 border-bottom">
                <div>
                  <h6 className="m-0">Internal Audit List</h6>
                </div>
                <div>
                  <AccessControl
                    type={AccessControlType.DISABLE}
                    allowedPermissions={[
                      UserPermissionsConstant.PerformAudit,
                      UserPermissionsConstant.MimicAccess,
                    ]}
                  >
                    <button
                      onClick={() => navigate("/add-new-audit")}
                      type="button"
                      className="btn btn-primary btn-sm px-4"
                      data-bs-toggle="modal"
                      data-bs-target="#modalCenter"
                    >
                      New Audit
                    </button>
                  </AccessControl>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive text-nowrap">
                  {/*<InternalAuditTable/>*/}
                  <DataTable
                    title=""
                    onSort={(selectedColumn, sortDirection, sortedRows)=>{
                        setSortesData(sortedRows);
                    }}
                    columns={columns}
                    defaultSortFieldId="code"
                    data={dataTableFormat}
                    pagination={true}
                    paginationPerPage={10}
                    paginationComponent={BootyPagination}
                    selectableRows={false}
                    customStyles={customStyles}
                    onRowClicked={(data: any) => {
                      dispatch({
                        type: GET_CRITERIA_LIST_BY_ID_SUCCESS,
                        payload: [],
                      });
                      dispatch({
                        type: GET_TEMPLATE_BY_ID_SUCCESS,
                        payload: undefined,
                      });
                      navigate(
                        "/internal-audit-results/" + data?.localTemplateHeaderId
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => {
  const auditManageMent = state?.auditManagement;
  const actualUser: any = state?.auth?.token ? jwt(state?.auth?.token) : undefined;
  const selectedClientDetails : clientType | undefined = state?.auth?.clientDetails;
  return {
    isLoading: auditManageMent?.isLoading,
    templateList: auditManageMent?.internalAuditLog,
    clientId: state?.auth?.clientDetails?.clientId,
    isClientAdmin: actualUser?.isSuperAdmin?.toLowerCase() !== "true",
    selectedClientDetails
  };
};
export default connect(mapStateToProps, null)(Home);
